<template>
  <v-autocomplete
    :items="items"
    v-model="model"
    item-text="value"
    item-value="key"
    :label="options.label"
    :placeholder="options.placeholder"
    :chips="options.chips"
    :hint="options.hint"
    :multiple="true"
  />
</template>

<script>
import axiosInstance from "../../axiosInstance";

export default {
  name: "field",
  props: ["value", "options"],
  data: () => ({
    items: [],
  }),
  created() {
    axiosInstance.get('entity/list', { params: { entity: [this.options.entity] } }).then((response) => {
      this.items = response.data;
    });
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>

<style scoped>

</style>
